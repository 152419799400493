<template>
  <vuestic-modal
    v-bind:large="true"
    ref="modal"
    cancelClass="none"
    okClass="none"
  >
    <div slot="title">Ouvidoria</div>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="novaOuvidoria.funcionario"
                id="simple-input-name"
              >
              <label
                for="simple-input-name"
                class="control-label"
              >Nome do Funcionario</label>
              <span
                class="alert-danger"
                v-if="erros.funcionario"
              >{{erros['funcionario'][0]}}</span>
            </div>
          </div>
        </div>
        <div class="col">
          <label class="select-control-label">Setor</label>
          <multiselect
            v-model="setorSelecionado"
            placeholder="Selecione"
            track-by="id"
            label="nome"
            :options="setores"
            selectedLabel="Selecionado"
            select-label="Selecione um setor"
            deselect-label="Remover setor"
          >
            <span slot="noResult">Setor não encontrado.</span>
            <template slot="noOptions">Sem setores</template>
          </multiselect>
          <span
            class="alert-danger mb-2"
            v-if="erros.setor"
          >{{erros['setor'][0]}}</span>
        </div>
        <div class="col">
          <label class="select-control-label">Situação</label>
          <multiselect
            v-model="status"
            placeholder="Selecione"
            track-by="id"
            label="nome"
            :options="situacao.array"
            selectedLabel="Selecionado"
            select-label="Selecione um status"
            deselect-label="Remover status"
          >
            <span slot="noResult">Status não encontrado.</span>
            <template slot="noOptions">Sem Status</template>
          </multiselect>
          <span
            class="alert-danger mb-2"
            v-if="erros.situacao"
          >{{erros['situacao'][0]}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <input
                v-model="novaOuvidoria.titulo"
                id="simple-input-title"
              >
              <label
                for="simple-input-title"
                class="control-label"
              >Titulo</label>
              <span
                class="alert-danger"
                v-if="erros.titulo"
              >{{erros['titulo'][0]}}</span>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <input
                type="date"
                v-model="novaOuvidoria.prazo"
                id="simple-input-prazo"
                required
              >
              <label
                class="select-control-label"
                for="simple-input-prazo"
              >Prazo</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <div class="input-group">
              <textarea
                v-model="novaOuvidoria.queixa"
                id="simple-input-call"
              />
              <label
                  for="simple-input-call"
                  class="control-label"
                >Descrição</label>
                <span
                  class="alert-danger"
                  v-if="erros.queixa"
                >{{erros['queixa'][0]}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col d-flex justify-content-center">
             <button class="btn btn-info btn-micro mr-3" @click="openModalAcao()">
              Abrir Ação
            </button>
              <button
                class="btn btn-primary btn-micro"
								@click="editarOuvidoria"
              >Salvar
              </button>
          </div>
        </div>
      </div>
          <modal-acao
            ref="modalLancarAcaoOuvidoria"
            :acao="acao"
            v-on:success="close"
          />
    </vuestic-modal>
</template>

<script>
import modalAcao from '@/components/user/form/modal/acao.vue';

export default {
  components: { modalAcao },
  props: {
    ouvidoria: {
      type: Object,
      required: true,
    },
    setores: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      acao: {
        ouvidoria: [],
        ferramenta: {
          id: 75,
          ferramenta: 'Ouvidoria',
        },
      },
      situacao: {
        array: [
          { id: 1, nome: 'Pendente' },
          { id: 2, nome: 'Cancelado' },
          { id: 3, nome: 'Tratado' }],
        nome: {
          1: 'Pendente',
          2: 'Cancelado',
          3: 'Tratado',
        },
        tipo: {
          1: 'danger',
          2: 'info',
          3: 'success',
        },
      },
      status: null,
      novaOuvidoria: {},
      erros: {},
      setorSelecionado: {},
    };
  },
  methods: {
    openModalAcao() {
      this.acao.ouvidoria = [this.ouvidoria.id];
      this.$refs.modalLancarAcaoOuvidoria.open();
    },
    open() {
      this.$refs.modal.open();
      this.erros = {};
    },
    close() {
      this.$refs.modal.close();
    },
    editarOuvidoria() {
      const { novaOuvidoria } = this;
      this.$axios.put(`/api/ouvidoria/${novaOuvidoria.id}/`, novaOuvidoria).then(() => {
        this.message('success', 'Alterações Salvas com Sucesso');
        this.$emit('success');
        this.erros = {};
        this.close();
      }).catch((error) => {
        this.erros = error.response.data;
        this.message('error', 'Erro ao Alterar');
      });
    },
    message(type, title) {
      this.$swal({
        toast: true,
        position: 'top-end',
        showCancelButton: false,
        type,
        title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
  },
  watch: {
    ouvidoria(val) {
      this.novaOuvidoria = JSON.parse(JSON.stringify(val));
      this.setorSelecionado = this.setores.find(s => s.id === val.setor);
      this.status = this.situacao.array.find(s => s.id === val.situacao);
    },
    status(val) {
      this.novaOuvidoria.situacao = val ? val.id : null;
    },
    setorSelecionado(val) {
      this.novaOuvidoria.setor = val ? val.id : null;
    },
  },
};
</script>

<style scoped>
.label-select {
	left: 22px !important;
}
</style>
